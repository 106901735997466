import { HMethod, httpRequest } from "./http";

/**
 * 获取用户信息
 * @param {string} userId 
 * @returns {Promise<HttpState&{data:UserInfo}>}
 */
export function getUserInfo(userId) {
    return httpRequest('/api/v1/user/h5/getUserInfo', HMethod.GET, { userId });
}

/**
 * 获取验证码
 * @param {string} phone 
 * @returns {Promise<HttpState&{data:{}}>}
 */
export function getSmsCode(phone) {
    return httpRequest('/api/v1/user/getSmsCode', HMethod.GET, { phone });
}

/**
 * 手机号登录
 * @param {string} phone 
 * @param {string} smsCode 
 * @returns {Promise<HttpState&{data:User}>}
 */
export function phoneLogin(phone, smsCode) {
    return httpRequest('/api/v1/user/phoneLogin', HMethod.POST, { phone, smsCode });
}