import { httpApi } from '../../../build/path.config';
import { getUser } from '../utils/user';

export const HMethod = {
    POST: "POST",
    GET: "GET",
};

const md5 = require('md5');


/**
 * 联网请求
 * @param {string} path  短路径
 * @param {HMethod} method 类型
 * @param {*=} data 数据
 * @param {string=} baseUrl 域名
 */
export function httpRequest(path, method, data, baseUrl) {

    return new Promise(c => {
        let xlh = null;
        let jsonData = null;
        let callBack = null;

        switch (method) {
            case HMethod.POST:
                xlh = getHttp(method, getSplitJointUrl(path, {}, baseUrl));
                xlh.setRequestHeader("Content-Type", "application/json");
                jsonData = JSON.stringify(data || {});
                console.log("POST:", getSplitJointUrl(path, {}, baseUrl), " param:", data);
                callBack = (info) => {
                    console.warn("POST:", getSplitJointUrl(path, {}, baseUrl), " result:", info);
                    c(info)
                };
                break;

            case HMethod.GET:
                xlh = getHttp(method, getSplitJointUrl(path, data, baseUrl));
                console.log("GET:", getSplitJointUrl(path, data, baseUrl));
                callBack = (info) => {
                    console.warn("GET:", getSplitJointUrl(path, data, baseUrl), " result:", info);
                    c(info)
                };
                break;
        }

        if (xlh) {
            sendRequest(xlh, callBack, jsonData);
        } else {
            console.error("网络类型未定义：", method, " path:", path);
            c();
        }
    });
}

/**
 * 发送
 * @param {XMLHttpRequest} xlh 
 * @param {Function} callBack 
 * @param {*=} data 
 */
function sendRequest(xlh, callBack, data) {
    data ? xlh.send(data) : xlh.send();
    xlh.onreadystatechange = (evt) => {
        parseRequest(evt, callBack)
    };
}

/**
 * 获取http实体
 * @param {HMethod} method 
 * @param {string} url 
 */
export function getHttp(method, url) {
    let xlh = new XMLHttpRequest();
    xlh.open(method, url, true);
    let nonce = getNonce();
    let timesamp = Date.now();

    xlh.setRequestHeader("VR-AppId", "10000");
    xlh.setRequestHeader("VR-Nonce", nonce);
    xlh.setRequestHeader("VR-Device", browserMsg());
    xlh.setRequestHeader("VR-Timestamp", `${timesamp}`);
    xlh.setRequestHeader("VR-Platform", `3`);
    xlh.setRequestHeader("VR-OSVersion", `H5:1.0`);
    xlh.setRequestHeader("VR-Version", `1.0.0`);
    xlh.setRequestHeader("VR-Token", `${getUser()?.token?.token || ""}`);
    // VR-UUID 设备唯⼀id
    xlh.setRequestHeader("VR-UUID", UUID());
    xlh.setRequestHeader("VR-Sign", sign(method, nonce, url, timesamp));
    return xlh;
}

/**
 * 解析返回数据
 * @param {*} evt 
 * @param {*} callBack 
 */
function parseRequest(evt, callBack) {
    let request = evt.currentTarget;
    if (request.readyState == 4) {
        if (request.status >= 200 && request.status < 400) {
            let data = request.responseText; //文本数据 用responseText
            let obj = JSON.parse(data); //由JSON字符串转换为JSON对象
            callBack && callBack(obj)
        } else {
            console.error("http is error!!");
        }
    }
}


/**
 * 拼接路径 进行字典排序
 * @param {string} shortPath 
 * @param {any} data 
 * @param {string} baseUrl 
 */
function getSplitJointUrl(shortPath, data, baseUrl) {
    let url = replaceDoubleSlash(`${baseUrl || httpApi}${shortPath}`);
    let idx = 0;
    for (let key in data) {
        url += `${idx == 0 ? '?' : '&'}${key}=${data[key]}`;
        idx++;
    }
    url = sortURL(url);
    return url;
}

/**
 * 替换当前路径中的双斜杠
 * @param {string} url 
 */
function replaceDoubleSlash(url) {
    if (!url) return "";
    let isHttps = url.slice(0, 8) === "https://";
    let spurl = isHttps ? url.slice(8) : url.slice(7);
    spurl = spurl.replace(/\/+/g, "/");
    let rurl = (isHttps ? "https://" : "http://") + spurl;
    return rurl;
}


/**
 * 将当前的地址进行排序
 *  @param {string} url 
 */
function sortURL(url) {
    let urls = url.split("?");
    if (urls.length === 1) return url;
    let preUrl = urls[0];
    let lastUrl = urls[1];
    let keyValueUrls = lastUrl.split("&").sort();// 默认字典排序
    let result = keyValueUrls.reduce((p, v, index) => {
        let idx = v.indexOf('=');// 从第一个=号开始匹配，兼容base64加密后的参数
        return p + (index === 0 ? "" : "&") + v.substr(0, idx) + "=" + v.substr(idx + 1, v.length);
    }, "");
    url = preUrl + "?" + result;
    return url;
}


function browserMsg() {
    let userAgent = navigator.userAgent.toLowerCase(); //取得浏览器的userAgent字符串
    if (userAgent.indexOf("baidu") > -1) return "Baidu";

    let isOpera = userAgent.indexOf("opera") > -1; //判断是否Opera浏览器
    let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器

    if (isIE) {
        let reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        let fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return "IE7";
        } else if (fIEVersion == 8) {
            return "IE8";
        } else if (fIEVersion == 9) {
            return "IE9";
        } else if (fIEVersion == 10) {
            return "IE10";
        } else if (fIEVersion == 11) {
            return "IE11";
        } else {
            return "IE";
        }
    }

    if (isOpera) return "Opera";

    //判断是否IE的Edge浏览器
    if (userAgent.indexOf("edge") > -1) return "Edge";
    //判断是否Firefox浏览器
    if (userAgent.indexOf("firefox") > -1) return "Firefox";
    //判断是否Safari浏览器
    if (userAgent.indexOf("safari") > -1 && userAgent.indexOf("Chrome") == -1) return "Safari";
    // 判断Chrome浏览器
    if (userAgent.indexOf("chrome") > -1 && userAgent.indexOf("Safari") > -1) return "Chrome";
    return "Other";
}

function UUID() {
    let key = "vroom.web.office.guid";
    let guid = localStorage.getItem(key);
    if (!guid) {
        guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
        localStorage.setItem(key, guid);
    }
    return guid;
}

/**
 * 签名
 * @param {string} method 
 * @param {string} nonce 
 */
function sign(method, nonce, url, timesamp) {
    let path = url.replace(httpApi, "");
    let str = `VR-AppId=10000,VR-Nonce=${nonce},VR-Timestamp=${timesamp}`;
    let secret = "klksafjljljoahfyuojaf1";
    return md5(method + str + path + secret).toUpperCase();
}

function getNonce() {
    let nonce = "";
    for (let index = 0; index < 6; index++) {
        nonce += `${Number((Math.random() * 8 + 1).toFixed(0))}`;
    }
    return nonce;
}
