let _user = null;

/**
 * 登录信息
 * @returns {User}
 */
export function getUser() {
    return _user;
}

export function setUser(info) {
    _user = info;
}