import { isPC } from "../utils/util";
import { HMethod, httpRequest } from "./http";

/**
 * 充值通道
 * @returns {Promise<HttpState&{data:PayWay[]}>}
 */
export function trade_payWay() {
    return httpRequest("/api/v1/trade/payWay", HMethod.GET);
}

/**
 * 商品列表
 * @param {1|2} goodsType 商品类型 1礼物 2金币
 * @returns {HttpState&{data:Good[]}}
 */
export function goods_list(goodsType) {
    return httpRequest('/api/v1/shop/goods/list', HMethod.GET, { goodsType });
}

/**
 * 充值
 * @param {number} goodsId 
 * @param {1|2} payWayCode wx 1 zfb2
 * @returns {Promise<HttpState&{data:TradeRecharge}>}
 */
export function trade_recharge(goodsId, payWayCode, userId) {
    // payTypeCode (1, "H5"),(2, "APP"),JS_API(3, "JSAPI"),NATIVE(4, "NATIVE"),
    return httpRequest("/api/v1/trade/recharge", HMethod.POST, { userId, goodsId, payWayCode, payTypeCode: isPC() ? 4 : 1 });
}