/**
 * 充值结果弹框
 */
export default {

    data() {
        return {

        }
    },

    created() {

    },

    methods: {
        // 支付完成
        async onComplete() {
            let res = { code: 1, msg: "支付" };
            if (res.code == 0) {
                this.$toast("充值成功");
                let url = location.href;
                url = url.replace(/&?orderNo=([a-z A-A]+[0-9]+)/, "")
                window.history.replaceState(null, '', url);
            } else {
                this.$toast("充值失败，请刷新页面重试");
            }
            this.onfail();
        },

        onfail() {
            dialog.closeOpenDialog(this);
        }
    },


}
