/**
 * 当前运行是否是pc 端
 * @returns {boolean}
 */
export function isPC() {
    let userAgentInfo = navigator.userAgent.toLowerCase();
    let agents = ["android", "iphone", "symbianos", "windows phone", "ipad", "ipod"];
    for (let i = 0, len = agents.length; i < len; i++) {
        if (userAgentInfo.indexOf(agents[i]) != -1) {
            return false
        };
    }
    return true;
}

/**
 * 获取路径中的参数
 * @param {string} paraName 
 * @returns {string}
 */
export function get_query_string(paraName) {
    let url = document.location.toString();
    let arrObj = url.split("?");

    if (arrObj.length > 1) {
        let arrPara = arrObj[1].split("&");
        let arr;

        for (let i = 0; i < arrPara.length; i++) {
            arr = arrPara[i].split("=");

            if (arr != null && arr[0] == paraName) {
                let res = arr[1];
                try {
                    res = decodeURIComponent(res);
                } catch (error) { }
                return res;
            }
        }
        return "";
    } else {
        return "";
    }
}
